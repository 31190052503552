<template>
	<div class="container">
		<!-- <common-head url="/newHome" :title="$t('tba.trusteeship')" :is-return="true">
			<div slot="right" class="head_right" @click="routerTo1()">{{ $t('tba.chengweilaoshi') }}</div>
		</common-head> -->
		<van-nav-bar
		    :title="$t('tba.trusteeship')"
		    left-arrow
		    @click-left="onClickLeft"
			@click-right="routerTo1"
			:right-text="$t('tba.chengweilaoshi')"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="nav">
				<div class="nav_item1">
					<span class="item" :class="{ active: nav_head == 0 }" @click="nav_head_select()">{{ $t('tba.woyaotuoguan') }}</span>
					<span class="item" @click="nav_head_select(1)">{{ $t('tba.wodetuoguan') }}</span>
				</div>
				<div class="nav_item2" v-if="nav_head == 0">
					<span class="item" :class="{ active: exchange === 'huobi' }" @click="exchange_select('huobi')">HUOBI</span>
					<span class="item" :class="{ active: exchange === 'bian' }" @click="exchange_select('bian')">BINANCE</span>
					<span class="item" :class="{ active: exchange === 'okex' }" @click="exchange_select('okex')">OKX</span>
					<span class="item" :class="{ active: exchange === 'uniswap' }" @click="exchange_select('uniswap')">UNISWAP</span>
				</div>
				<div class="nav_item3" v-if="nav_head == 0">
					<span class="item" :class="{ active: classTab == 1 }" @click="type(1)">{{ $t('tba.xianhuojiaoyi') }}</span>
					<span class="item" :class="{ active: classTab == 2 }" @click="type(2)">{{ $t('tba.heyuejiaoyi') }}</span>
				</div>
				<div class="nav_item4" v-if="nav_head == 0">
					<div class="item4_1" :class="{ active1: tab_index == 1 }" @click="look_tab(1)">{{ $t('tba.gensuizhe') }}</div>
					<div class="item4_2" :class="{ active1: tab_index == 2 }" @click="look_tab(2)">{{ $t('tba.onedayshouyi') }}</div>
					<div class="item4_3" :class="{ active1: tab_index == 3 }" @click="look_tab(3)">{{ $t('tba.Totalrevenue') }}</div>
				</div>
			</div>
			<div class="content">
				<van-list
					v-model="billLoading"
					:finished="billFinished"
					:finished-text="$t('cashFlow.NoMore')"
					:loading-text="$t('common.loading')"
					@load="onLoadcashFlow"
					v-if="nav_head == 0"
				>
					<van-row class="teacher_list" v-for="(item, index) in billList" :key="index" @click="routerTo2(item)">
						<van-col span="12">
							<div class="msg_left">
								<div class="img"><img :src="item.avatar" alt="" /></div>
								<div class="name">{{ item.deposit_name }}</div>
								<div class="VIP">
									<!-- <div class="img_vip">
			                            <img src="@/assets/imgs/big_vip.png" alt="" v-if="item.level > 0" />
			                            <img src="@/assets/imgs/small_vip.png" alt="" v-else />
			                        </div> -->
									<div class="vip_number" v-if="item.level > 0">VIP{{ item.level }}</div>
									<div class="vip_number" v-else>{{ $t('tba.putongVIP') }}</div>
								</div>
								<div class="bili">{{ $t('tba.fenchengbili') }}{{ item.brokerage_ratio | fencheng }}%</div>
							</div>
						</van-col>
						<van-col span="15">
							<div class="msg_right">
								<div class="top">
									<div class="top_left">
										<div class="left_num">{{ item.total_trusteeship_user }}</div>
										<div class="txt">{{ $t('tba.zongdaidanrenshu') }}</div>
									</div>
									<div class="top_right">
										<div class="right_num">{{ (item.year_rate * 100) | baoliu }}%</div>
										<div class="txt">{{ $t('tba.nianhuashouyi') }}</div>
									</div>
								</div>
								<div class="bottom">
									<div class="top_left">
										<div class="left_num">{{ item.todayusdt | baoliu }}</div>
										<div class="txt">{{ $t('tba.jinrishouyi') }}</div>
									</div>
									<div class="top_right">
										<div class="left_num">{{ item.totalusdt | baoliu }}</div>
										<div class="txt">{{ $t('tba.leijishouyi') }}</div>
									</div>
								</div>
							</div>
						</van-col>
					</van-row>
				</van-list>
			</div>
		</div>
	</div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
	components: { commonHead },
	data() {
		return {
			list: [],
			nav_head: 0,
			exchange: 'huobi',
			classTab: 1,
			tab_index: 1,
			state: 0,
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			billPage: 1
		};
	},
	created() {
		// this.getBalance();
	},
	filters: {
		fencheng(v) {
			return v * 100;
		},
		baoliu(v) {
			return v ? parseFloat(v).toFixed(2) : '0.00';
		}
	},
	computed: {
		sortfudu() {
			return this.sortByKey(this.list, 'total_trusteeship_user').reverse();
		},
		sortfudu1() {
			return this.sortByKey(this.list, 'todayusdt').reverse();
		},
		sortfudu2() {
			return this.sortByKey(this.list, 'totalusdt').reverse();
		}
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		sortByKey(array, key) {
			return array.sort((a, b) => {
				let x = a[key];
				let y = b[key];
				return x < y ? -1 : x > y ? 1 : 0; //小到大
			});
		},
		nav_head_select() {
			this.$router.push('/new_trusteeship2');
		},
		exchange_select(e) {
			this.exchange = e;
			this.billData = [];
			this.billList = [];
			this.billPage = 1;
			this.billLoading = false;
			this.billFinished = false;
			// this.onLoadcashFlow()
		},
		type(e) {
			this.classTab = e;
			this.billData = [];
			this.billList = [];
			this.billPage = 1;
			this.billLoading = false;
			this.billFinished = false;
			// this.onLoadcashFlow()
		},
		look_tab(e) {
			this.tab_index = e;
			this.billData = [];
			this.billList = [];
			this.billPage = 1;
			this.billLoading = false;
			this.billFinished = false;
			// this.onLoadcashFlow()
		},
		routerTo1() {
			this.$router.push({
				path: '/im_teacher',
				query: {}
			});
		},
		//跳转托管详情
		routerTo2(e) {
			this.$router.push({
				path: '/new_trusteeshio_msg',
				query: {
					config_id: e.id
				}
			});
		},
		//获取各个交易所的开启的托管套餐列表
		getTrusteeshipConfigList() {
			this.$post2('Deposit/Api/Index/getTrusteeshipConfigList', {
				exchange: this.exchange,
				deposit_type: this.classTab,
				page: this.billPage++,
				pagesize: 100
			})
				.then(res => {
					if (res.list) {
						if (this.tab_index == 1) {
							this.list = res.list;
							this.billData = this.sortfudu;
						} else if (this.tab_index == 2) {
							this.list = res.list;
							this.billData = this.sortfudu1;
						} else if (this.tab_index == 3) {
							this.list = res.list;
							this.billData = this.sortfudu2;
						}
					} else {
						this.billData = [];
					}
					// console.log(this.billData);
					for (let i = 0; i < this.billData.length; i++) {
						this.billList.push(this.billData[i]);
					}
					// console.log(this.shouyiList);
					// 加载状态结束
					this.billLoading = false;
					// 数据全部加载完成

					if (this.billData.length < 1) {
						this.billFinished = true;
					}
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},

		onLoadcashFlow() {
			this.getTrusteeshipConfigList();
			// 异步充值记录
		}
	}
};
</script>

<style scoped lang="less">
.container {
	background-color: #f7f7fa;
	height: 100vh;
	font-family: PingFangSC-Regular;
	// height: calc(100vh + 110px);
	.content {
		background-color: #f7f7fa;
		padding-bottom: 1rem;
	}
	.head_right {
		color: #333333;
		font-size: 14px;
	}
}
.nav {
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	background-color: #ffffff;
	font-family: PingFang SC;
	padding: 0.15rem 0;
	.nav_item1 {
		font-size: 14px;
		padding: 0.15rem 33px;
		color: #8f9eab;
		// border-bottom: 1px solid #f1f1f1;
		.item {
			margin-left: 30px;
		}
		.item:first-child {
			margin-left: 0;
		}
	}
	.nav_item2 {
		font-size: 14px;
		padding: 0.15rem 33px;
		color: #8f9eab;
		display: flex;
		justify-content: space-between;
		// border-bottom: 1px solid #f1f1f1;
		.item {
			// margin-left: 20px;
			text-align: center;
		}
		.item:first-child {
			margin-left: 0;
		}
	}
	.nav_item3 {
		font-size: 14px;
		padding: 0.15rem 33px;
		color: #8f9eab;
		// border-bottom: 1px solid #f1f1f1;
		.item {
			margin-left: 30px;
			text-align: center;
		}
		.item:first-child {
			margin-left: 0;
		}
	}
	.nav_item4 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		padding: 0.15rem 34px;
		color: #8f9eab;
		.item4_1 {
			border-radius: 4px;
			background-color: #f7f7fa;
			padding: 6px 5px 6px 5px;
			text-align: center;
		}
		.item4_2 {
			border-radius: 4px;
			background-color: #f7f7fa;
			padding: 6px 5px 6px 5px;
			text-align: center;
		}
		.item4_3 {
			border-radius: 4px;
			background-color: #f7f7fa;
			padding: 6px 5px 6px 5px;
			text-align: center;
		}
		.active1 {
			color: #333;
			font-weight: 600;
		}
	}
	.active {
		color: #333333;
		font-size: 14px;
		font-weight: 600;
	}
}
.content {
	.teacher_list {
		background-color: #ffffff;
		margin-top: 10px;
		// box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
		font-family: PingFang SC;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		display: flex;
		justify-content: space-between;
		padding: 0.12rem 0;
		.msg_left {
			margin: 10px 0;
			// margin-left: 0.1rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			.img {
				width: 1.2rem;
				height: 1.2rem;
				img {
					border-radius: 0.5rem;
					width: 100%;
					height: 100%;
				}
			}
			.name {
				font-size: 0.28rem;
				font-weight: 500;
				color: #333333;
				text-align: center;
			}
			.VIP {
				display: flex;
				align-items: center;
				.img_vip {
					// width: 0.24rem;
					// height: 0.24rem;
					img {
						width: 0.252399rem;
						height: 0.2218rem;
					}
				}
				.vip_number {
					font-family: 'haose';
					margin-left: 0.05rem;
					font-size: 0.28rem;
					color: #2E62FF;
				}
			}
			.bili {
				margin-top: 0.1rem;
				background-color: rgba(46, 98, 255, 0.09);
				padding: 0.08rem 0.28rem 0.06rem 0.28rem;
				border-radius: 0.08rem;
				font-size: 0.2rem;
				transform: scale(0.91);
				color: #2E62FF;
				text-align: center;
				font-weight: 600;
			}
		}
		.msg_right {
			// margin-left: 0.3rem;
			margin-top: 0.6rem;
			.top {
				display: flex;
				align-items: center;
				.top_left {
					text-align: left;
				}
				.top_right {
					margin-left: 0.58rem;
					text-align: left;
				}
				.txt {
					color: #6f7a8b;
					font-size: 0.24rem;
				}
				.left_num {
					color: #333333;
					font-size: 0.32rem;
				}
				.right_num {
					color: #32bf88;
					font-size: 0.32rem;
				}
			}
			.bottom {
				margin-top: 0.6rem;
				display: flex;
				align-items: center;
				.top_left {
					text-align: left;
				}
				.top_right {
					margin-left: 0.2rem;
					text-align: left;
				}
				.txt {
					color: #6f7a8b;
					font-size: 0.24rem;
				}
				.left_num {
					color: #333333;
					font-size: 0.32rem;
					font-weight: 600;
				}
				.right_num {
					color: #333333;
					font-size: 0.32rem;
					font-weight: 600;
				}
			}
		}
		.msg_right1 {
			margin-top: 1.04rem;
			margin-right: 0.72rem;
			.top {
				display: flex;
				align-items: center;
				.top_left {
					text-align: left;
				}
				.top_right {
					margin-left: 0.58rem;
					text-align: left;
				}
				.txt {
					text-align: left;
					margin-left: -0.09rem;
					color: #666666;
					font-size: 0.24rem;
					transform: scale(0.83);
				}
				.left_num {
					color: #5998fc;
					font-size: 0.3rem;
				}
				.right_num {
					color: #d98181;
					font-size: 0.3rem;
				}
			}
			.bottom {
				margin-top: 0.16rem;
				display: flex;
				align-items: center;
				.top_left {
					text-align: left;
				}
				.top_right {
					margin-left: 0.8rem;
					text-align: left;
				}
				.txt {
					text-align: left;
					margin-left: -0.09rem;
					color: #666666;
					font-size: 0.24rem;
					transform: scale(0.83);
				}
				.txt1 {
					margin-top: 0.15rem;
					text-align: left;
					color: #666666;
					font-size: 0.24rem;
				}
				.left_num {
					color: #5998fc;
					font-size: 0.3rem;
				}
				.right_num {
					color: #d98181;
					font-size: 0.3rem;
				}
			}
		}
	}
}
</style>
